import {
  BtnClearFormatting,
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from 'react-simple-wysiwyg';
import { StyledLabel, Icon } from './FormFieldStyles';
import alignCenter from '../../../assets/icons/alignCenter.svg'
import alignLeft from '../../../assets/icons/alignLeft.svg'
import alignRight from '../../../assets/icons/alignRight.svg'

export const EditorField = ({ value, setFieldValue, name, label }) => {
  const onChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  
  
  const BtnAlignCenter = createButton('Align center', <Icon src={alignCenter} />, 'justifyCenter');
  const BtnAlignLeft = createButton('Align left', <Icon src={alignLeft} />, 'justifyLeft');
  const BtnAlignRight = createButton('Align right', <Icon src={alignRight} />, 'justifyRight');
  //const BtnClearFormat = createButton('Clear formatting', '🧽', 'removeFormat');

  return (
    <EditorProvider>
      {!!label && <StyledLabel>{label}</StyledLabel>}
      <Editor value={value} onChange={onChange}>
        <Toolbar>
          <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnStrikeThrough />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <BtnAlignCenter />
          <BtnAlignLeft />
          <BtnAlignRight />
          <Separator />
          <BtnClearFormatting />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};

/** HTML to React parser
 * https://github.com/remarkablemark/html-react-parser
import parse from 'html-react-parser';
<TableCell>{parse(item.description)}</TableCell>
 * **/
