import { useParams } from 'react-router-dom';
import { Document, PDFViewer, Font } from '@react-pdf/renderer';
import { useImagesByPropertyId, usePropertyById } from '../../services/propertiesService';
import { Loader } from '../shared/Loader';
import { styles } from './presentationStyles';
import { FirstPage } from './FirstPage';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';
import { SecondPage } from './SecondPage';

Font.register({
    family: 'Manrope',
    src: manropeFont,
  });
  Font.register({
    family: 'Cormorant700',
    src: cormorantFont,
  });
  


export const PresentationPage = () => {
  const { propertyId } = useParams();
  const { data: property, isLoading } = usePropertyById(propertyId);
  const { data: images, isLoading: isImgLoading } = useImagesByPropertyId(propertyId);
  const mainImage = images?.data.find((img: string) => img.includes('main'));
  const mainImageUrl = `https://assets.ipi.market/${mainImage}`;
  const restImages = images?.data.filter((img: string) => !img.includes('main'));
  const secondImageUrl = !!restImages ? `https://assets.ipi.market/${restImages[0]}` : '';
  
  //console.log('property', property?.data);
  //console.log('secondImage', secondImageUrl);
  const propertyData = property?.data;

  if (!property || !images || isLoading || isImgLoading) return <Loader />;
    

  return(
    <>
    <div>debug mode</div>
    <PDFViewer style={styles.viewer}>
    <Document>
      <FirstPage propertyData={propertyData} mainImageUrl={mainImageUrl}/>
      <SecondPage propertyData={propertyData} imageUrl={secondImageUrl}/>
    </Document>
  </PDFViewer>
  </>
    )
}