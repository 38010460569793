import { useParams } from 'react-router-dom';
import { usePropertyById, useOfficesByPropertyId } from '../../../services/propertiesService';
import { PropertyPageWrapper, StyledLink, PageHeader, Icon, StyledLinkButton } from './PropertyPageStyle';
import { OfficesTable } from './OfficesTable';
import { useState } from 'react';
import arrow_back from '../../../assets/icons/arrow_back.svg';
import { HtmlParser } from '../../../utils/HtmlParser';

export const PropertyPage = () => {
  const { propertyId } = useParams();
  const [selectedOffices, setSelectedOffices] = useState<string[]>([]);
  const { data: property } = usePropertyById(propertyId); 
  const { data: offices, mutate, isLoading } = useOfficesByPropertyId(propertyId);
  return (
    <PropertyPageWrapper>
      <PageHeader>
        <StyledLink to={'/properties'}>
          <Icon src={arrow_back} /> повернутися до списку{' '}
        </StyledLink>
      </PageHeader>
      <h1>{property?.data.name}</h1>
      <div>{property?.data.shortDesc}</div>
      {/* <HtmlParser   htmlString={property?.data.description} /> */}
      <div>
        {offices && (
          <OfficesTable
            offices={offices.data}
            selectedOffices={selectedOffices}
            setSelectedOffices={setSelectedOffices}
          />
        )}
      </div>
      <StyledLinkButton to={`/presentation/${propertyId}`} target='_blank' disabled={selectedOffices.length === 0}>Завантажити PDF</StyledLinkButton>
    </PropertyPageWrapper>
  );
};
