import { PropertyType } from '../../types';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { styles } from './presentationStyles';
import logoBeige from '../../assets/images/logo-beige.png'
import logoWhite from '../../assets/images/logo-white.png';
import { Characteristics } from './Characteristics';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';

Font.register({
    family: 'Manrope',
    src: manropeFont,
  });
  Font.register({
    family: 'Cormorant700',
    src: cormorantFont,
  });
  

interface FirstPageProps {
    propertyData: PropertyType;
    mainImageUrl: string;
}

export const FirstPage = ({propertyData, mainImageUrl}:FirstPageProps) => {
 return (
    <Page size={{ width: 595, height: 842 }} style={styles.wrapper}>
    {/* Header */}
    <View style={styles.header}>
      <View style={styles.logo}>
        <Image style={styles.logoImg} src={logoBeige} />
      </View>
      <View style={styles.text}>
        <Text>Phone: +123456789009</Text>
        <Text>Website: ipi.market</Text>
      </View>
    </View>

    {/* Content */}
    <View style={styles.content}>
      {/* Top section with image and info box */}
      <View style={styles.topSection}>
        <Image style={styles.mainBcImg} src={mainImageUrl} />
        <View style={styles.bcInfo}>
          <Text style={styles.name}>{propertyData.name}</Text>
          <Text style={styles.address}>{propertyData.address}</Text>
          <View style={styles.square}>
            <Text>{propertyData.gba_m2} sq.m</Text>
            <Text>total available area</Text>
          </View>
        </View>
      </View>

      {/* Middle section */}
      <View style={styles.middleSection}>
        {/* Description */}
        <View  style={styles.textSection}>
          <Text style={styles.title}>Опис</Text>
          <Text style={styles.text}>
          {propertyData.shortDesc}                
          </Text>
        </View>
        {/* Advantages */}
        <View style={styles.characteristicsSection}>
        <Text style={styles.title}>Загальна характеристика</Text>
        <Characteristics property={propertyData}/>
        </View>            
      </View>
    </View>

    {/* Footer */}
    <View style={styles.footer}>
      <View style={styles.logo}>
        <Image style={styles.logoImg} src={logoWhite} />
      </View>
      <View style={styles.text}>
        <Text>Phone: +123456789009</Text>
        <Text>Website: www.ipi.com</Text>
      </View>
    </View>
  </Page>
 )
}