import axios, { AxiosRequestConfig } from 'axios';
import { getCurrentToken } from './authenticate';

const client = axios.create({
  baseURL: 'https://api.ipi.market/', //for real server
  //baseURL: 'http://localhost:3002', //for local server Mockoon
  timeout: 30000,  
});

// Axios request interceptor to attach token before each request
client.interceptors.request.use(async (config) => {

  // Otherwise, attach the token
  const token = await getCurrentToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, (error) => {
  return Promise.reject(error);
});



export const getJsonAsync = <Response = any>(endpoint: string, headers?: AxiosRequestConfig['headers']) =>
  client.get<Response>(endpoint, { headers });

export const getJsonAsyncWithParams = <Response = any>(endpoint: string, params?: any) =>
  client.get<Response>(endpoint, { params });

export const postJsonAsync = <T = any>(endpoint: string, payload: any, headers?: AxiosRequestConfig['headers']) =>
  client
    .post<T>(endpoint, payload, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error('An error occurred during the POST request:', error);
      throw error; // Re-throw the error to indicate that an error occurred.
    });

export const putJsonAsync = <T = any>(endpoint: string, payload: any, headers?: AxiosRequestConfig['headers']) =>
  client
    .put<T>(endpoint, payload, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('An error occurred during the POST request:', error);
      throw error; // Re-throw the error to indicate that an error occurred.
    });

export const deleteJsonAsync = (endpoint: string, headers?: AxiosRequestConfig['headers']) =>
  client
    .delete(endpoint, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error('An error occurred during the POST request:', error);
      throw error; // Re-throw the error to indicate that an error occurred.
    });
