import { useFormikContext, FieldArray } from 'formik';
import { InputField } from '../../shared/formFields/InputField';
import { TextAreaField } from '../../shared/formFields/TextAreaField';
import { SelectField } from '../../shared/formFields/SelectField';
import { Checkbox } from '../../shared/formFields/Checkbox';
import { PropertyType } from '../../../types';
import { classOptions } from './options';
import {
  GroupWrapper,
  ColumnWrapper,
  RowWrapper,
  SelectWrapper,
  CheckboxWrapper,
  FieldsWrapper,
  Button, 
  Icon
} from './PropertyFormStyle';
import { EditorField } from '../../shared/formFields/EditorField';
import { BoxButton } from '../../shared/buttons/BoxButton';
import deleteButton from '../../../assets/icons/deleteButton.svg';


export const DescriptionFields = () => {
  const { values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<PropertyType>();
  return (
    <RowWrapper>
      <ColumnWrapper>
        <InputField
          label='Назва'
          name='name'
          value={values.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          required
        />
        <TextAreaField
          label='Короткий опис'
          name='shortDesc'
          value={values.shortDesc}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          rows={8}
        />
        <GroupWrapper>
          <SelectWrapper>
            <SelectField
              name='class'
              label='Виберіть клас БЦ'
              value={values.class}
              options={classOptions}
              handleChange={setFieldValue}
              handleBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              touched={touched}
              errors={errors}
              required
            />
          </SelectWrapper>
          <CheckboxWrapper>
            <Checkbox label={'Опубліковано'} name={'active'} handleChange={(isChecked) => setFieldValue('active', isChecked)} checked={!!values.active} />
          </CheckboxWrapper>
        </GroupWrapper>
        <FieldArray name='advantages'>
          {({ push, remove }) => {
            return (
              <FieldsWrapper>
                <div>Переваги:</div>
                {values.advantages?.map((advantage, index) => {
                  return (
                    <div key={index}>
                      <InputField
                        name={`advantages[${index}]`}
                        value={values.advantages[index]}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        required
                      />
                      <Button type='button' onClick={() => remove(index)}>
                        <Icon src={deleteButton} /> <span>Видалити</span>
                      </Button>
                    </div>
                  );
                })}
                <BoxButton ariaLabel='add' onClick={() => push('')} $padding='5px' type='button' disabled={values.advantages?.length>14}>
                  Додати перевагу
                </BoxButton>
              </FieldsWrapper>
            );
          }}
        </FieldArray>
      </ColumnWrapper>
      <ColumnWrapper>
        <EditorField value={values.description} setFieldValue={setFieldValue} name={'description'} label='Опис' />
      </ColumnWrapper>
    </RowWrapper>
  );
};
