import { Text } from '@react-pdf/renderer';
import { PropertyType } from '../../types';
import { styles } from './presentationStyles';

interface CharacteristicsProps {
  property: PropertyType;
}
export const Characteristics = ({ property }: CharacteristicsProps) => {
  const {
    shelter,
    generator,
    heating,
    energySupply,
    fireprotect,
    security,
    ventilation,
    parking,
    lifts,
    floors,
    access,
  } = property;
  return (
    <>
      {!!shelter && <Text style={styles.listItem}>• Укриття: +</Text>}
      {!!generator && <Text style={styles.listItem}>• Резервне живлення: +</Text>}
      {!!energySupply && <Text style={styles.listItem}>• Енергопостачання: {energySupply}</Text>}
      {!!heating && <Text style={styles.listItem}>• Опалення: {heating}</Text>}
      {!!fireprotect && <Text style={styles.listItem}>• Противопожежна система: {fireprotect}</Text>}
      {!!security && <Text style={styles.listItem}>• Система безпеки: {security}</Text>}
      {!!ventilation && <Text style={styles.listItem}>• Система вентиляції і кондиціонування: {ventilation}</Text>}
      {!!parking && <Text style={styles.listItem}>• Парковка для автомобілів: {parking}</Text>}
      {!!lifts && <Text style={styles.listItem}>• Ліфтова система: {lifts}</Text>}
      {!!floors && <Text style={styles.listItem}>• Кількість поверхів: {floors}</Text>}
      {!!access && <Text style={styles.listItem}>• Доступ до БЦ: {access}</Text>}
    </>
  );
};
