import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../components/authentication/userpool';

export const authenticate = (Email, Password) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Email,
      Pool: userpool,
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.log('login failed', err);
        reject(err);
      },
    });
  });
};
const user = userpool.getCurrentUser();

export const logout = () => {
  console.log('user', user);
  user?.signOut();
  window.location.href = '/auth';
};

const isTokenExpired = (token) => {
  if (!token) return true;

  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  const expTime = tokenPayload.exp * 1000;
  return Date.now() > expTime;
};

export const getCurrentToken = async () => {
  return new Promise((resolve, reject) => {
    const user = userpool.getCurrentUser();
    if (!user) {
      reject(new Error("No current user found."));
      return;
    }

    user.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      if (!session || !session.isValid()) {
        reject(new Error("Session is invalid."));
        return;
      }

      // Check if token is expired, and refresh if necessary
      const token = session.getIdToken().getJwtToken();
      const isExpired = isTokenExpired(token);
      
      if (isExpired) {
        // Refresh token
        user.refreshSession(session.getRefreshToken(), (refreshError, newSession) => {
          if (refreshError) {
            reject(refreshError);
            return;
          }
          resolve(newSession.getIdToken().getJwtToken()); // Return new token
        });
      } else {
        resolve(token); // Return existing valid token
      }
    });
  });
};
