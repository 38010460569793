import { Outlet, useMatch } from "react-router-dom";
import styled from "styled-components";
import { Header } from "./layouts/header/Header";
import { Footer } from './layouts/header/Footer';

export const AppContainer = styled.div``;

export const Navigation = () => {
  const matchPresentation = useMatch('/presentation/:propertyId');
  const isPresentationPage = Boolean(matchPresentation); // will be true if the current path matches

  return (
    <>
      {!isPresentationPage && <Header />}
      <Outlet />
      {!isPresentationPage && <Footer />} 
    </>
  );
};
