import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const PropertyPageWrapper = styled.div`
  min-height: 500px;
  padding: 20px;
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  &:hover {
    color: black;
  }
`;

export const Icon = styled.img`
  cursor: pointer;
`;

export const StyledLinkButton = styled(Link)<{ disabled: boolean }>`
  display: inline-block;
  cursor: pointer;
  margin: 20px 0;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.golden};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.blue};
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, width 0.3s ease;
  padding: 12px 5px;
  
  &:hover {
    color: ${(props) => props.theme.darkGray};
  }

  &:hover {
    ${(props) => !props.disabled && css`
      background-color: ${props.theme.softBlue};
      border-color: transparent;
      color: ${props.theme.white};
    `}
  }

  ${(props) =>
    props.disabled &&
    css`
    color: ${(props) => props.theme.darkGray};
    background-color: ${(props) => props.theme.mediumGray};
    border-color: ${(props) => 'transparent'};
    cursor: default;
    opacity: 0.5;
    `}
`;
