import { OfficeType } from '../../../types';
import { Table, TableCell, TableHeader, TableRow } from '../../shared/Table';
import styled from 'styled-components';
import { Checkbox } from '../../shared/formFields/Checkbox';
import { ChangeEvent } from 'react';
import { addOrRemoveString } from '../../../utils/arrayFunctions';

export const Icon = styled.img`
  cursor: pointer;
`;

const OfficesTableHeader = () => {
  return (
    <>
      <TableHeader>
        <span>Поверх</span>
      </TableHeader>
      <TableHeader>
        <span>Площа</span>
      </TableHeader>
      <TableHeader>
        <span>Орендна ставка</span>
      </TableHeader>
      <TableHeader>
        <span>Валюта</span>
      </TableHeader>
    </>
  );
};

interface UserRowProps {
  item: OfficeType;
  $even: boolean;
  selectedOffices: string[];
  setSelectedOffices: React.Dispatch<React.SetStateAction<string[]>>;
}

const OfficeTableRow = ({ item, $even, selectedOffices, setSelectedOffices }: UserRowProps) => {
    console.log('selectedOffices', selectedOffices)
  return (
    <>
      <TableRow $even={$even}>
        <TableCell>
          <Checkbox
            label={item.name}
            name={''}
            handleChange={() => {
                const newSelected = addOrRemoveString(selectedOffices, item.id);
                setSelectedOffices(newSelected);
            }}
          />
        </TableCell>
        <TableCell>{item.square}</TableCell>
        <TableCell>{item.price}</TableCell>
        <TableCell>{item.currency}</TableCell>
      </TableRow>
    </>
  );
};

interface OfficesTableProps {
  offices: OfficeType[];
  selectedOffices: string[];
  setSelectedOffices: React.Dispatch<React.SetStateAction<string[]>>;
}

export const OfficesTable = ({ offices, selectedOffices, setSelectedOffices }: OfficesTableProps) => {
  return (
    <Table $gridTemplateColumns='3fr 2fr 3fr 1fr'>
      <OfficesTableHeader />
      {offices?.map((office: OfficeType, i: number) => (
        <OfficeTableRow
          item={office}
          key={office.id}
          $even={i % 2 === 0}
          selectedOffices={selectedOffices}
          setSelectedOffices={setSelectedOffices}
        />
      ))}
    </Table>
  );
};
