import { useState } from 'react';
import { BoxButton } from '../../../shared/buttons/BoxButton';
import { OfficeFormModal } from './OfficeFormModal';
import { useOfficesByPropertyId } from '../../../../services/propertiesService';
import { OfficesTable } from './OfficesTable';
import { InputField } from '../../../shared/formFields/InputField';
import { Loader } from '../../../shared/Loader';

interface OfficesFieldsProps {
  bcId: string;
  totalM2: number;
  setTotalM2: React.Dispatch<React.SetStateAction<number>>;
}
export const OfficesFields = ({
  bcId,
  totalM2,
  setTotalM2,
}: OfficesFieldsProps) => {

  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const {
    data: offices,
    mutate,
    isLoading,
  } = useOfficesByPropertyId(bcId);

  if (!offices || isLoading) return <Loader />;

  return (
    <>
      <div>Офіси:</div>
      <OfficesTable offices={offices.data} bcId={bcId} mutate={mutate}/>
      {isAddFormVisible && (
        <OfficeFormModal
          bcId={bcId}
          office={null}
          handleCloseModal={() => setIsAddFormVisible(false)}
          mutate={mutate}
        />
      )}
      <InputField
        label='Загальна доступна площа, кв.м.'
        name='totalM2'
        value={totalM2 || ''}
        handleChange={(e: any) => setTotalM2(Number(e.target.value))}
        $width='220px'
        type='number'
      />
      <BoxButton
        ariaLabel='add'
        $padding='5px'
        type='button'
        onClick={() => setIsAddFormVisible(true)}
      >
        Add Office
      </BoxButton>
    </>
  );
};
