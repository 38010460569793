import { PropertyType } from '../../types';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { styles } from './presentationStyles';
import manropeFont from '../../assets/fonts/Manrope-Regular.ttf';
import cormorantFont from '../../assets/fonts/Cormorant-Bold.ttf';

Font.register({
  family: 'Manrope',
  src: manropeFont,
});
Font.register({
  family: 'Cormorant700',
  src: cormorantFont,
});

interface FirstPageProps {
  propertyData: PropertyType;
  imageUrl: string;
}

export const SecondPage = ({ propertyData, imageUrl }: FirstPageProps) => {
    const mapImgUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${propertyData.lat},${propertyData.lng}&zoom=14&size=260x250&markers=color:red|${propertyData.lat},${propertyData.lng}&key=AIzaSyClZ-GshxM6BC29STVpg7Go-oFoeqwP6vM`
  return <Page size={{ width: 595, height: 842 }} style={styles.wrapper}>
    <View style={styles.topSection}>
    <Image style={styles.mapImg} src={mapImgUrl} />
    </View>
  </Page>;
};
