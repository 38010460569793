import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { StyledLabel, DataPickerWrapper } from './FormFieldStyles';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { getFriendlyDate } from '../../../utils/dateFunctions';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
interface DatePickerFieldProps {
  name: string;
  value: Value;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export const DatePickerField = ({ name, value, label, required, disabled }: DatePickerFieldProps) => {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const [date, setDate] = useState<Value>(value);

  const onChange = (inputValue: Value) => {
    setDate(inputValue);
    if (inputValue instanceof Date) {
      setFieldValue(name, getFriendlyDate(inputValue));
    } else {
      setFieldValue(name, '');
    }
    setFieldTouched(name)
  }

  return (
    <DataPickerWrapper>
      {!!label && (
        <StyledLabel required={required} htmlFor={name} disabled={disabled} $isError={false}>
          {label}
        </StyledLabel>
      )}
      <DatePicker value={date} onChange={onChange} format={"dd.MM.y"} locale="uk"/>
    </DataPickerWrapper>
  );
};
